export const people_data = {
   "alex-straffon": {
      "name": "Alex Straffon",
      "photos": [
         {
            "faces": [],
            "height": 4467,
            "name": "IMG_3077.jpg",
            "slug": "img-3077-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3077-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3077-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg",
            "width": 6700
         },
         {
            "faces": [],
            "height": 3290,
            "name": "IMG_3078.jpg",
            "slug": "img-3078-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1142_img-3078-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x731_img-3078-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1142_img-3078-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x357_img-3078-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x571_img-3078-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x357_img-3078-jpg.jpg",
            "width": 4606
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 3197,
            "name": "IMG_3168.jpg",
            "slug": "img-3168-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x640_img-3168-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x500_img-3168-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg",
            "width": 5115
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3762,
            "name": "IMG_3276.jpg",
            "slug": "img-3276-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x553_img-3276-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x432_img-3276-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg",
            "width": 6959
         },
         {
            "faces": [],
            "height": 3251,
            "name": "IMG_3232.jpg",
            "slug": "img-3232-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x575_img-3232-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x449_img-3232-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3316.jpg",
            "slug": "img-3316-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3316-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3316-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4060,
            "name": "IMG_3279.jpg",
            "slug": "img-3279-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x639_img-3279-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x499_img-3279-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg",
            "width": 6497
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 3880,
            "name": "IMG_3274.jpg",
            "slug": "img-3274-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3274-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3274-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg",
            "width": 5820
         },
         {
            "faces": [],
            "height": 5394,
            "name": "IMG_3329.jpg",
            "slug": "img-3329-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2400_img-3329-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3329-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2400_img-3329-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3329-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1200_img-3329-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3329-jpg.jpg",
            "width": 3596
         },
         {
            "faces": [],
            "height": 6496,
            "name": "IMG_3445.jpg",
            "slug": "img-3445-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2240_img-3445-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3445-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2240_img-3445-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x700_img-3445-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1120_img-3445-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x700_img-3445-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5733,
            "name": "IMG_3453.jpg",
            "slug": "img-3453-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2240_img-3453-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3453-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2240_img-3453-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x700_img-3453-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1120_img-3453-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x700_img-3453-jpg.jpg",
            "width": 4095
         },
         {
            "faces": [],
            "height": 3816,
            "name": "IMG_3670.jpg",
            "slug": "img-3670-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-3670-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-3670-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-3670-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3670-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3670-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3670-jpg.jpg",
            "width": 3171
         },
         {
            "faces": [],
            "height": 6033,
            "name": "IMG_3674.jpg",
            "slug": "img-3674-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2240_img-3674-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3674-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2240_img-3674-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3674-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1120_img-3674-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3674-jpg.jpg",
            "width": 4309
         },
         {
            "faces": [],
            "height": 5305,
            "name": "IMG_4005.jpg",
            "slug": "img-4005-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg",
            "width": 3789
         },
         {
            "faces": [],
            "height": 6275,
            "name": "IMG_4112.jpg",
            "slug": "img-4112-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4112-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4112-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg",
            "width": 4183
         },
         {
            "faces": [],
            "height": 5013,
            "name": "IMG_4148.jpg",
            "slug": "img-4148-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4148-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4148-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg",
            "width": 4010
         },
         {
            "faces": [],
            "height": 5684,
            "name": "IMG_4082.jpg",
            "slug": "img-4082-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4082-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4082-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4082-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4082-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4082-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4082-jpg.jpg",
            "width": 4060
         },
         {
            "faces": [],
            "height": 5220,
            "name": "IMG_4162.jpg",
            "slug": "img-4162-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4162-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4162-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg",
            "width": 4176
         },
         {
            "faces": [],
            "height": 6326,
            "name": "IMG_4182.jpg",
            "slug": "img-4182-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4182-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4182-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4182-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4182-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4182-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4182-jpg.jpg",
            "width": 4217
         }
      ],
      "slug": "alex-straffon",
      "src": "/static/_gallery/albums/loop-marquesa/alex-straffon_original_img-3077-jpg.jpg"
   },
   "carlos-olivier": {
      "name": "Carlos Olivier",
      "photos": [
         {
            "faces": [],
            "height": 4467,
            "name": "IMG_3077.jpg",
            "slug": "img-3077-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3077-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3077-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg",
            "width": 6700
         },
         {
            "faces": [],
            "height": 3290,
            "name": "IMG_3078.jpg",
            "slug": "img-3078-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1142_img-3078-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x731_img-3078-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1142_img-3078-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x357_img-3078-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x571_img-3078-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x357_img-3078-jpg.jpg",
            "width": 4606
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 4167,
            "name": "IMG_3121.jpg",
            "slug": "img-3121-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1600_img-3121-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1024_img-3121-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1600_img-3121-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x500_img-3121-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x800_img-3121-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x500_img-3121-jpg.jpg",
            "width": 4167
         },
         {
            "faces": [],
            "height": 5436,
            "name": "IMG_3084.jpg",
            "slug": "img-3084-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3084-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3084-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3084-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3084-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3084-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3084-jpg.jpg",
            "width": 3883
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3085.jpg",
            "slug": "img-3085-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3085-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3085-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 4281,
            "name": "IMG_3304.jpg",
            "slug": "img-3304-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3304-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3304-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg",
            "width": 5352
         },
         {
            "faces": [],
            "height": 3251,
            "name": "IMG_3232.jpg",
            "slug": "img-3232-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x575_img-3232-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x449_img-3232-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3316.jpg",
            "slug": "img-3316-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3316-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3316-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4202,
            "name": "IMG_3306.jpg",
            "slug": "img-3306-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg",
            "width": 5253
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 4180,
            "name": "IMG_3300.jpg",
            "slug": "img-3300-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3300-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3300-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg",
            "width": 6270
         },
         {
            "faces": [],
            "height": 5021,
            "name": "IMG_3463.jpg",
            "slug": "img-3463-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3463-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3463-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3463-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x624_img-3463-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3463-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x624_img-3463-jpg.jpg",
            "width": 4017
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3468.jpg",
            "slug": "img-3468-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3468-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3468-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3468-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3468-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3468-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3468-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5711,
            "name": "IMG_3895.jpg",
            "slug": "img-3895-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3895-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3895-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg",
            "width": 4079
         },
         {
            "faces": [],
            "height": 5364,
            "name": "IMG_3638.jpg",
            "slug": "img-3638-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3638-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3638-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3638-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3638-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3638-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3638-jpg.jpg",
            "width": 3831
         },
         {
            "faces": [],
            "height": 3887,
            "name": "IMG_3896.jpg",
            "slug": "img-3896-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1066_img-3896-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x682_img-3896-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1066_img-3896-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x333_img-3896-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x533_img-3896-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x333_img-3896-jpg.jpg",
            "width": 5831
         },
         {
            "faces": [],
            "height": 4102,
            "name": "IMG_3601.jpg",
            "slug": "img-3601-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1066_img-3601-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x682_img-3601-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1066_img-3601-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x333_img-3601-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x533_img-3601-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x333_img-3601-jpg.jpg",
            "width": 6154
         },
         {
            "faces": [],
            "height": 5249,
            "name": "IMG_3642.jpg",
            "slug": "img-3642-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-3642-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-3642-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-3642-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-3642-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-3642-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-3642-jpg.jpg",
            "width": 4199
         },
         {
            "faces": [],
            "height": 4911,
            "name": "IMG_3914.jpg",
            "slug": "img-3914-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1231_img-3914-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3914-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg",
            "width": 4082
         },
         {
            "faces": [],
            "height": 6186,
            "name": "IMG_3644.jpg",
            "slug": "img-3644-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2133_img-3644-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1365_img-3644-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2133_img-3644-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x666_img-3644-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1066_img-3644-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x666_img-3644-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 4913,
            "name": "IMG_3924.jpg",
            "slug": "img-3924-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2240_img-3924-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3924-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2240_img-3924-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3924-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1120_img-3924-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3924-jpg.jpg",
            "width": 3509
         },
         {
            "faces": [],
            "height": 4439,
            "name": "IMG_3940.jpg",
            "slug": "img-3940-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1142_img-3940-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x731_img-3940-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1142_img-3940-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x357_img-3940-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x571_img-3940-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x357_img-3940-jpg.jpg",
            "width": 6215
         },
         {
            "faces": [],
            "height": 4838,
            "name": "IMG_4021.jpg",
            "slug": "img-4021-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4021-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4021-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4021-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4021-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4021-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4021-jpg.jpg",
            "width": 3225
         },
         {
            "faces": [],
            "height": 5305,
            "name": "IMG_4005.jpg",
            "slug": "img-4005-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg",
            "width": 3789
         },
         {
            "faces": [],
            "height": 4640,
            "name": "IMG_4050.jpg",
            "slug": "img-4050-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1066_img-4050-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x682_img-4050-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1066_img-4050-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x333_img-4050-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x533_img-4050-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x333_img-4050-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 5672,
            "name": "IMG_4015.jpg",
            "slug": "img-4015-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg",
            "width": 4537
         },
         {
            "faces": [],
            "height": 6727,
            "name": "IMG_4077.jpg",
            "slug": "img-4077-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2399_img-4077-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1535_img-4077-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2399_img-4077-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x749_img-4077-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1199_img-4077-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x749_img-4077-jpg.jpg",
            "width": 4485
         },
         {
            "faces": [],
            "height": 3624,
            "name": "IMG_3954.jpg",
            "slug": "img-3954-jpg",
            "src": "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x639_img-3954-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x499_img-3954-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg",
            "width": 5799
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_4068.jpg",
            "slug": "img-4068-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4068-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4068-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5584,
            "name": "IMG_4044.jpg",
            "slug": "img-4044-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4044-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4044-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg",
            "width": 4467
         },
         {
            "faces": [],
            "height": 5748,
            "name": "IMG_3997.jpg",
            "slug": "img-3997-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3997-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3997-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg",
            "width": 4105
         },
         {
            "faces": [],
            "height": 4640,
            "name": "IMG_4047.jpg",
            "slug": "img-4047-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1066_img-4047-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x682_img-4047-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1066_img-4047-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x333_img-4047-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x533_img-4047-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x333_img-4047-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 5013,
            "name": "IMG_4148.jpg",
            "slug": "img-4148-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4148-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4148-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg",
            "width": 4010
         }
      ],
      "slug": "carlos-olivier",
      "src": "/static/_gallery/albums/loop-marquesa/carlos-olivier_original_img-3077-jpg.jpg"
   },
   "cristina-l-esquivelzeta": {
      "name": "Cristina L Esquivelzeta",
      "photos": [
         {
            "faces": [],
            "height": 4467,
            "name": "IMG_3077.jpg",
            "slug": "img-3077-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3077-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3077-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg",
            "width": 6700
         },
         {
            "faces": [],
            "height": 3295,
            "name": "IMG_3162.jpg",
            "slug": "img-3162-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1280_img-3162-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3162-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1280_img-3162-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x400_img-3162-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x640_img-3162-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x400_img-3162-jpg.jpg",
            "width": 4118
         },
         {
            "faces": [],
            "height": 3290,
            "name": "IMG_3078.jpg",
            "slug": "img-3078-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1142_img-3078-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x731_img-3078-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1142_img-3078-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x357_img-3078-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x571_img-3078-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x357_img-3078-jpg.jpg",
            "width": 4606
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3085.jpg",
            "slug": "img-3085-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3085-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3085-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 4281,
            "name": "IMG_3304.jpg",
            "slug": "img-3304-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3304-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3304-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg",
            "width": 5352
         },
         {
            "faces": [],
            "height": 3251,
            "name": "IMG_3232.jpg",
            "slug": "img-3232-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x575_img-3232-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x449_img-3232-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3316.jpg",
            "slug": "img-3316-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3316-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3316-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4202,
            "name": "IMG_3306.jpg",
            "slug": "img-3306-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg",
            "width": 5253
         },
         {
            "faces": [],
            "height": 4060,
            "name": "IMG_3279.jpg",
            "slug": "img-3279-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x639_img-3279-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x499_img-3279-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg",
            "width": 6497
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 5164,
            "name": "IMG_3314.jpg",
            "slug": "img-3314-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3314-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3314-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3314-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3314-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3314-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3314-jpg.jpg",
            "width": 3689
         },
         {
            "faces": [],
            "height": 5036,
            "name": "IMG_3372.jpg",
            "slug": "img-3372-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2262_img-3372-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1448_img-3372-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2262_img-3372-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x707_img-3372-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1131_img-3372-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x707_img-3372-jpg.jpg",
            "width": 3561
         },
         {
            "faces": [],
            "height": 5625,
            "name": "IMG_3381.jpg",
            "slug": "img-3381-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1599x2263_img-3381-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1448_img-3381-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1599x2263_img-3381-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x707_img-3381-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/799x1131_img-3381-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x707_img-3381-jpg.jpg",
            "width": 3977
         },
         {
            "faces": [],
            "height": 5656,
            "name": "IMG_3378.jpg",
            "slug": "img-3378-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2262_img-3378-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1448_img-3378-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2262_img-3378-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x707_img-3378-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1131_img-3378-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x707_img-3378-jpg.jpg",
            "width": 3999
         }
      ],
      "slug": "cristina-l-esquivelzeta",
      "src": "/static/_gallery/albums/loop-marquesa/cristina-l-esquivelzeta_original_img-3077-jpg.jpg"
   },
   "diego-lopez": {
      "name": "Diego Lopez",
      "photos": [
         {
            "faces": [],
            "height": 5711,
            "name": "IMG_3895.jpg",
            "slug": "img-3895-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3895-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3895-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg",
            "width": 4079
         },
         {
            "faces": [],
            "height": 4911,
            "name": "IMG_3914.jpg",
            "slug": "img-3914-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1231_img-3914-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3914-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg",
            "width": 4082
         },
         {
            "faces": [],
            "height": 5583,
            "name": "IMG_3921.jpg",
            "slug": "img-3921-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-3921-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-3921-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-3921-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3921-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3921-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3921-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 4439,
            "name": "IMG_3940.jpg",
            "slug": "img-3940-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1142_img-3940-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x731_img-3940-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1142_img-3940-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x357_img-3940-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x571_img-3940-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x357_img-3940-jpg.jpg",
            "width": 6215
         },
         {
            "faces": [],
            "height": 3457,
            "name": "IMG_4030.jpg",
            "slug": "img-4030-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4030-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4030-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4030-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4030-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4030-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4030-jpg.jpg",
            "width": 2765
         },
         {
            "faces": [],
            "height": 5305,
            "name": "IMG_4005.jpg",
            "slug": "img-4005-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg",
            "width": 3789
         },
         {
            "faces": [],
            "height": 5672,
            "name": "IMG_4015.jpg",
            "slug": "img-4015-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg",
            "width": 4537
         },
         {
            "faces": [],
            "height": 3624,
            "name": "IMG_3954.jpg",
            "slug": "img-3954-jpg",
            "src": "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x639_img-3954-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x499_img-3954-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg",
            "width": 5799
         },
         {
            "faces": [],
            "height": 6495,
            "name": "IMG_4081.jpg",
            "slug": "img-4081-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-4081-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4081-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-4081-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-4081-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-4081-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-4081-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 6275,
            "name": "IMG_4112.jpg",
            "slug": "img-4112-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4112-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4112-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg",
            "width": 4183
         },
         {
            "faces": [],
            "height": 5189,
            "name": "IMG_4127.jpg",
            "slug": "img-4127-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4127-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4127-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4127-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4127-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4127-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4127-jpg.jpg",
            "width": 4151
         },
         {
            "faces": [],
            "height": 4784,
            "name": "IMG_4122.jpg",
            "slug": "img-4122-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2000_img-4122-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4122-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2000_img-4122-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4122-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1000_img-4122-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4122-jpg.jpg",
            "width": 3827
         }
      ],
      "slug": "diego-lopez",
      "src": "/static/_gallery/albums/otomi/diego-lopez_original_img-3895-jpg.jpg"
   },
   "fer-agassi": {
      "name": "Fer Agassi",
      "photos": [
         {
            "faces": [],
            "height": 5393,
            "name": "IMG_3116.jpg",
            "slug": "img-3116-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1599x2400_img-3116-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3116-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1599x2400_img-3116-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3116-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/799x1200_img-3116-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3116-jpg.jpg",
            "width": 3595
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 3197,
            "name": "IMG_3168.jpg",
            "slug": "img-3168-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x640_img-3168-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x500_img-3168-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg",
            "width": 5115
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3251,
            "name": "IMG_3232.jpg",
            "slug": "img-3232-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x575_img-3232-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x449_img-3232-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3316.jpg",
            "slug": "img-3316-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3316-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3316-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 6654,
            "name": "IMG_3338.jpg",
            "slug": "img-3338-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2400_img-3338-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3338-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2400_img-3338-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3338-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1200_img-3338-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3338-jpg.jpg",
            "width": 4436
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4202,
            "name": "IMG_3306.jpg",
            "slug": "img-3306-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg",
            "width": 5253
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 5007,
            "name": "IMG_3340.jpg",
            "slug": "img-3340-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1599x2400_img-3340-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3340-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1599x2400_img-3340-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3340-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/799x1200_img-3340-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3340-jpg.jpg",
            "width": 3338
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 3880,
            "name": "IMG_3274.jpg",
            "slug": "img-3274-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3274-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3274-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg",
            "width": 5820
         },
         {
            "faces": [],
            "height": 6332,
            "name": "IMG_3677.jpg",
            "slug": "img-3677-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-3677-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3677-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-3677-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-3677-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-3677-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-3677-jpg.jpg",
            "width": 4523
         },
         {
            "faces": [],
            "height": 3698,
            "name": "IMG_3675.jpg",
            "slug": "img-3675-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-3675-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3675-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-3675-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-3675-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-3675-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-3675-jpg.jpg",
            "width": 2642
         },
         {
            "faces": [],
            "height": 5305,
            "name": "IMG_4005.jpg",
            "slug": "img-4005-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg",
            "width": 3789
         },
         {
            "faces": [],
            "height": 4079,
            "name": "IMG_4085.jpg",
            "slug": "img-4085-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4085-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4085-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4085-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4085-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4085-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4085-jpg.jpg",
            "width": 2913
         },
         {
            "faces": [],
            "height": 5584,
            "name": "IMG_4044.jpg",
            "slug": "img-4044-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4044-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4044-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg",
            "width": 4467
         },
         {
            "faces": [],
            "height": 5748,
            "name": "IMG_3997.jpg",
            "slug": "img-3997-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3997-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3997-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg",
            "width": 4105
         },
         {
            "faces": [],
            "height": 6275,
            "name": "IMG_4112.jpg",
            "slug": "img-4112-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4112-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4112-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg",
            "width": 4183
         },
         {
            "faces": [],
            "height": 5220,
            "name": "IMG_4162.jpg",
            "slug": "img-4162-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4162-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4162-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg",
            "width": 4176
         }
      ],
      "slug": "fer-agassi",
      "src": "/static/_gallery/albums/loop-marquesa/fer-agassi_original_img-3116-jpg.jpg"
   },
   "hugo-rodrigo": {
      "name": "Hugo Rodrigo",
      "photos": [
         {
            "faces": [],
            "height": 5088,
            "name": "IMG_3093.jpg",
            "slug": "img-3093-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3093-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3093-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3093-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x624_img-3093-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3093-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x624_img-3093-jpg.jpg",
            "width": 4071
         },
         {
            "faces": [],
            "height": 5632,
            "name": "IMG_3126.jpg",
            "slug": "img-3126-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2240_img-3126-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3126-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2240_img-3126-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x700_img-3126-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1120_img-3126-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x700_img-3126-jpg.jpg",
            "width": 4022
         },
         {
            "faces": [],
            "height": 4913,
            "name": "IMG_3094.jpg",
            "slug": "img-3094-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3094-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3094-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3094-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3094-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3094-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3094-jpg.jpg",
            "width": 3930
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 3197,
            "name": "IMG_3168.jpg",
            "slug": "img-3168-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x640_img-3168-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x500_img-3168-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg",
            "width": 5115
         },
         {
            "faces": [],
            "height": 4367,
            "name": "IMG_3095.jpg",
            "slug": "img-3095-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3095-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3095-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3095-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x624_img-3095-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3095-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x624_img-3095-jpg.jpg",
            "width": 3494
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3100.jpg",
            "slug": "img-3100-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3100-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3100-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3100-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3100-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3100-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3100-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3762,
            "name": "IMG_3276.jpg",
            "slug": "img-3276-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x553_img-3276-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x432_img-3276-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg",
            "width": 6959
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3085.jpg",
            "slug": "img-3085-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3085-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3085-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3251,
            "name": "IMG_3232.jpg",
            "slug": "img-3232-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x575_img-3232-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x449_img-3232-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 6654,
            "name": "IMG_3338.jpg",
            "slug": "img-3338-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2400_img-3338-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3338-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2400_img-3338-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3338-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1200_img-3338-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3338-jpg.jpg",
            "width": 4436
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4060,
            "name": "IMG_3279.jpg",
            "slug": "img-3279-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x639_img-3279-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x499_img-3279-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg",
            "width": 6497
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 3880,
            "name": "IMG_3274.jpg",
            "slug": "img-3274-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3274-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3274-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg",
            "width": 5820
         },
         {
            "faces": [],
            "height": 6281,
            "name": "IMG_3351.jpg",
            "slug": "img-3351-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3351-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3351-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3351-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3351-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3351-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3351-jpg.jpg",
            "width": 4487
         },
         {
            "faces": [],
            "height": 5697,
            "name": "IMG_3362.jpg",
            "slug": "img-3362-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3362-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3362-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3362-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x624_img-3362-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3362-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x624_img-3362-jpg.jpg",
            "width": 4558
         },
         {
            "faces": [],
            "height": 4057,
            "name": "IMG_3665.jpg",
            "slug": "img-3665-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-3665-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-3665-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-3665-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3665-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3665-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3665-jpg.jpg",
            "width": 3372
         },
         {
            "faces": [],
            "height": 5711,
            "name": "IMG_3895.jpg",
            "slug": "img-3895-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3895-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3895-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg",
            "width": 4079
         },
         {
            "faces": [],
            "height": 4102,
            "name": "IMG_3601.jpg",
            "slug": "img-3601-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1066_img-3601-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x682_img-3601-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1066_img-3601-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x333_img-3601-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x533_img-3601-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x333_img-3601-jpg.jpg",
            "width": 6154
         },
         {
            "faces": [],
            "height": 4911,
            "name": "IMG_3914.jpg",
            "slug": "img-3914-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1231_img-3914-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3914-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg",
            "width": 4082
         },
         {
            "faces": [],
            "height": 4349,
            "name": "IMG_3603.jpg",
            "slug": "img-3603-jpg",
            "src": "/static/_gallery/albums/otomi/1600x999_img-3603-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x639_img-3603-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x999_img-3603-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x312_img-3603-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x499_img-3603-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x312_img-3603-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 4439,
            "name": "IMG_3940.jpg",
            "slug": "img-3940-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1142_img-3940-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x731_img-3940-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1142_img-3940-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x357_img-3940-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x571_img-3940-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x357_img-3940-jpg.jpg",
            "width": 6215
         },
         {
            "faces": [],
            "height": 5193,
            "name": "IMG_4073.jpg",
            "slug": "img-4073-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4073-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4073-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4073-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4073-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4073-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4073-jpg.jpg",
            "width": 4154
         },
         {
            "faces": [],
            "height": 4496,
            "name": "IMG_3945.jpg",
            "slug": "img-3945-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1279_img-3945-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x819_img-3945-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1279_img-3945-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x399_img-3945-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x639_img-3945-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x399_img-3945-jpg.jpg",
            "width": 5621
         },
         {
            "faces": [],
            "height": 5672,
            "name": "IMG_4015.jpg",
            "slug": "img-4015-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg",
            "width": 4537
         },
         {
            "faces": [],
            "height": 3624,
            "name": "IMG_3954.jpg",
            "slug": "img-3954-jpg",
            "src": "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x639_img-3954-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x499_img-3954-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg",
            "width": 5799
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_4068.jpg",
            "slug": "img-4068-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4068-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4068-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5584,
            "name": "IMG_4044.jpg",
            "slug": "img-4044-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4044-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4044-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg",
            "width": 4467
         },
         {
            "faces": [],
            "height": 6275,
            "name": "IMG_4112.jpg",
            "slug": "img-4112-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4112-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4112-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg",
            "width": 4183
         },
         {
            "faces": [],
            "height": 4640,
            "name": "IMG_4047.jpg",
            "slug": "img-4047-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1066_img-4047-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x682_img-4047-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1066_img-4047-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x333_img-4047-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x533_img-4047-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x333_img-4047-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 5189,
            "name": "IMG_4127.jpg",
            "slug": "img-4127-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4127-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4127-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4127-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4127-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4127-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4127-jpg.jpg",
            "width": 4151
         },
         {
            "faces": [],
            "height": 4784,
            "name": "IMG_4122.jpg",
            "slug": "img-4122-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2000_img-4122-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4122-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2000_img-4122-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4122-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1000_img-4122-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4122-jpg.jpg",
            "width": 3827
         }
      ],
      "slug": "hugo-rodrigo",
      "src": "/static/_gallery/albums/loop-marquesa/hugo-rodrigo_original_img-3093-jpg.jpg"
   },
   "jose-luis-sosa": {
      "name": "Jose Luis Sosa",
      "photos": [
         {
            "faces": [],
            "height": 4467,
            "name": "IMG_3077.jpg",
            "slug": "img-3077-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3077-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3077-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg",
            "width": 6700
         },
         {
            "faces": [],
            "height": 3290,
            "name": "IMG_3078.jpg",
            "slug": "img-3078-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1142_img-3078-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x731_img-3078-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1142_img-3078-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x357_img-3078-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x571_img-3078-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x357_img-3078-jpg.jpg",
            "width": 4606
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 4167,
            "name": "IMG_3121.jpg",
            "slug": "img-3121-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1600_img-3121-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1024_img-3121-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1600_img-3121-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x500_img-3121-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x800_img-3121-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x500_img-3121-jpg.jpg",
            "width": 4167
         },
         {
            "faces": [],
            "height": 3197,
            "name": "IMG_3168.jpg",
            "slug": "img-3168-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x640_img-3168-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x500_img-3168-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg",
            "width": 5115
         },
         {
            "faces": [],
            "height": 5436,
            "name": "IMG_3084.jpg",
            "slug": "img-3084-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3084-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3084-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3084-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3084-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3084-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3084-jpg.jpg",
            "width": 3883
         },
         {
            "faces": [],
            "height": 3762,
            "name": "IMG_3276.jpg",
            "slug": "img-3276-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x553_img-3276-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x432_img-3276-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg",
            "width": 6959
         },
         {
            "faces": [],
            "height": 4283,
            "name": "IMG_3260.jpg",
            "slug": "img-3260-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1142_img-3260-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x731_img-3260-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1142_img-3260-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x357_img-3260-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x571_img-3260-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x357_img-3260-jpg.jpg",
            "width": 5996
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3085.jpg",
            "slug": "img-3085-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3085-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3085-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3251,
            "name": "IMG_3232.jpg",
            "slug": "img-3232-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x575_img-3232-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x449_img-3232-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4202,
            "name": "IMG_3306.jpg",
            "slug": "img-3306-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg",
            "width": 5253
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 3880,
            "name": "IMG_3274.jpg",
            "slug": "img-3274-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3274-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3274-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg",
            "width": 5820
         },
         {
            "faces": [],
            "height": 5243,
            "name": "IMG_3334.jpg",
            "slug": "img-3334-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3334-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3334-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3334-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x624_img-3334-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3334-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x624_img-3334-jpg.jpg",
            "width": 4195
         },
         {
            "faces": [],
            "height": 6496,
            "name": "IMG_3510.jpg",
            "slug": "img-3510-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2240_img-3510-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3510-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2240_img-3510-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x700_img-3510-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1120_img-3510-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x700_img-3510-jpg.jpg",
            "width": 4640
         }
      ],
      "slug": "jose-luis-sosa",
      "src": "/static/_gallery/albums/loop-marquesa/jose-luis-sosa_original_img-3077-jpg.jpg"
   },
   "laura-luna": {
      "name": "Laura Luna",
      "photos": [
         {
            "faces": [],
            "height": 5066,
            "name": "IMG_3613.jpg",
            "slug": "img-3613-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1999_img-3613-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1279_img-3613-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1999_img-3613-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x624_img-3613-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x999_img-3613-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x624_img-3613-jpg.jpg",
            "width": 4053
         },
         {
            "faces": [],
            "height": 6441,
            "name": "IMG_3846.jpg",
            "slug": "img-3846-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-3846-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3846-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-3846-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-3846-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-3846-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-3846-jpg.jpg",
            "width": 4601
         },
         {
            "faces": [],
            "height": 5307,
            "name": "IMG_3701.jpg",
            "slug": "img-3701-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-3701-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3701-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-3701-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-3701-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-3701-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-3701-jpg.jpg",
            "width": 3791
         },
         {
            "faces": [],
            "height": 5305,
            "name": "IMG_4005.jpg",
            "slug": "img-4005-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg",
            "width": 3789
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_4068.jpg",
            "slug": "img-4068-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4068-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4068-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5843,
            "name": "IMG_4078.jpg",
            "slug": "img-4078-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2400_img-4078-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4078-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2400_img-4078-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4078-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1200_img-4078-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4078-jpg.jpg",
            "width": 3895
         },
         {
            "faces": [],
            "height": 5748,
            "name": "IMG_3997.jpg",
            "slug": "img-3997-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3997-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3997-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg",
            "width": 4105
         },
         {
            "faces": [],
            "height": 4888,
            "name": "IMG_4072.jpg",
            "slug": "img-4072-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-4072-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-4072-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-4072-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x601_img-4072-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-4072-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x601_img-4072-jpg.jpg",
            "width": 4062
         },
         {
            "faces": [],
            "height": 6275,
            "name": "IMG_4112.jpg",
            "slug": "img-4112-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4112-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4112-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg",
            "width": 4183
         }
      ],
      "slug": "laura-luna",
      "src": "/static/_gallery/albums/otomi/laura-luna_original_img-3613-jpg.jpg"
   },
   "mark-rodriguez": {
      "name": "Mark Rodriguez",
      "photos": [
         {
            "faces": [],
            "height": 5044,
            "name": "IMG_3704.jpg",
            "slug": "img-3704-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-3704-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-3704-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-3704-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-3704-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-3704-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-3704-jpg.jpg",
            "width": 4035
         },
         {
            "faces": [],
            "height": 5973,
            "name": "IMG_3707.jpg",
            "slug": "img-3707-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-3707-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3707-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-3707-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x699_img-3707-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-3707-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x699_img-3707-jpg.jpg",
            "width": 4267
         },
         {
            "faces": [],
            "height": 6960,
            "name": "IMG_3712.jpg",
            "slug": "img-3712-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-3712-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-3712-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-3712-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-3712-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-3712-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-3712-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 6318,
            "name": "IMG_4000.jpg",
            "slug": "img-4000-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-4000-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4000-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-4000-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-4000-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-4000-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-4000-jpg.jpg",
            "width": 4513
         },
         {
            "faces": [],
            "height": 5307,
            "name": "IMG_3701.jpg",
            "slug": "img-3701-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-3701-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3701-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-3701-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-3701-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-3701-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-3701-jpg.jpg",
            "width": 3791
         },
         {
            "faces": [],
            "height": 5953,
            "name": "IMG_3850.jpg",
            "slug": "img-3850-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2240_img-3850-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3850-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2240_img-3850-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3850-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1120_img-3850-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3850-jpg.jpg",
            "width": 4252
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_4068.jpg",
            "slug": "img-4068-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4068-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4068-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5748,
            "name": "IMG_3997.jpg",
            "slug": "img-3997-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3997-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3997-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg",
            "width": 4105
         },
         {
            "faces": [],
            "height": 5583,
            "name": "IMG_4070.jpg",
            "slug": "img-4070-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-4070-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-4070-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-4070-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-4070-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-4070-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-4070-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5637,
            "name": "IMG_3998.jpg",
            "slug": "img-3998-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3998-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3998-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3998-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3998-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3998-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3998-jpg.jpg",
            "width": 4026
         },
         {
            "faces": [],
            "height": 5013,
            "name": "IMG_4148.jpg",
            "slug": "img-4148-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4148-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4148-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg",
            "width": 4010
         }
      ],
      "slug": "mark-rodriguez",
      "src": "/static/_gallery/albums/otomi/mark-rodriguez_original_img-3704-jpg.jpg"
   },
   "octavio-m": {
      "name": "Octavio M",
      "photos": [
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 6040,
            "name": "IMG_3128.jpg",
            "slug": "img-3128-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2240_img-3128-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3128-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2240_img-3128-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x700_img-3128-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1120_img-3128-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x700_img-3128-jpg.jpg",
            "width": 4314
         },
         {
            "faces": [],
            "height": 3197,
            "name": "IMG_3168.jpg",
            "slug": "img-3168-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x640_img-3168-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x500_img-3168-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg",
            "width": 5115
         },
         {
            "faces": [],
            "height": 5436,
            "name": "IMG_3084.jpg",
            "slug": "img-3084-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3084-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3084-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3084-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3084-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3084-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3084-jpg.jpg",
            "width": 3883
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3762,
            "name": "IMG_3276.jpg",
            "slug": "img-3276-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x553_img-3276-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x432_img-3276-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg",
            "width": 6959
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3085.jpg",
            "slug": "img-3085-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3085-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3085-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3085-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3085-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 4281,
            "name": "IMG_3304.jpg",
            "slug": "img-3304-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3304-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3304-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg",
            "width": 5352
         },
         {
            "faces": [],
            "height": 3251,
            "name": "IMG_3232.jpg",
            "slug": "img-3232-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x575_img-3232-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x449_img-3232-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3316.jpg",
            "slug": "img-3316-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3316-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3316-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4202,
            "name": "IMG_3306.jpg",
            "slug": "img-3306-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg",
            "width": 5253
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 5513,
            "name": "IMG_3325.jpg",
            "slug": "img-3325-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3325-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3325-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3325-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3325-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3325-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3325-jpg.jpg",
            "width": 3938
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 3880,
            "name": "IMG_3274.jpg",
            "slug": "img-3274-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3274-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3274-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg",
            "width": 5820
         },
         {
            "faces": [],
            "height": 4675,
            "name": "IMG_3368.jpg",
            "slug": "img-3368-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2262_img-3368-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1448_img-3368-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2262_img-3368-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x707_img-3368-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1131_img-3368-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x707_img-3368-jpg.jpg",
            "width": 3306
         },
         {
            "faces": [],
            "height": 4180,
            "name": "IMG_3300.jpg",
            "slug": "img-3300-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3300-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3300-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg",
            "width": 6270
         },
         {
            "faces": [],
            "height": 5494,
            "name": "IMG_3375.jpg",
            "slug": "img-3375-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2262_img-3375-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1448_img-3375-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2262_img-3375-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x707_img-3375-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1131_img-3375-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x707_img-3375-jpg.jpg",
            "width": 3885
         },
         {
            "faces": [],
            "height": 3864,
            "name": "IMG_3596.jpg",
            "slug": "img-3596-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1142_img-3596-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x731_img-3596-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1142_img-3596-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x357_img-3596-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x571_img-3596-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x357_img-3596-jpg.jpg",
            "width": 5410
         },
         {
            "faces": [],
            "height": 5711,
            "name": "IMG_3895.jpg",
            "slug": "img-3895-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3895-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3895-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg",
            "width": 4079
         },
         {
            "faces": [],
            "height": 3887,
            "name": "IMG_3896.jpg",
            "slug": "img-3896-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1066_img-3896-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x682_img-3896-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1066_img-3896-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x333_img-3896-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x533_img-3896-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x333_img-3896-jpg.jpg",
            "width": 5831
         },
         {
            "faces": [],
            "height": 5249,
            "name": "IMG_3642.jpg",
            "slug": "img-3642-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-3642-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-3642-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-3642-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-3642-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-3642-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-3642-jpg.jpg",
            "width": 4199
         },
         {
            "faces": [],
            "height": 4911,
            "name": "IMG_3914.jpg",
            "slug": "img-3914-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1231_img-3914-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3914-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg",
            "width": 4082
         },
         {
            "faces": [],
            "height": 5583,
            "name": "IMG_3921.jpg",
            "slug": "img-3921-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-3921-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-3921-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-3921-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3921-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3921-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3921-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3650.jpg",
            "slug": "img-3650-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-3650-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-3650-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-3650-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-3650-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-3650-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-3650-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 4439,
            "name": "IMG_3940.jpg",
            "slug": "img-3940-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1142_img-3940-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x731_img-3940-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1142_img-3940-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x357_img-3940-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x571_img-3940-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x357_img-3940-jpg.jpg",
            "width": 6215
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3652.jpg",
            "slug": "img-3652-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-3652-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-3652-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-3652-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-3652-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-3652-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-3652-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 4496,
            "name": "IMG_3945.jpg",
            "slug": "img-3945-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1279_img-3945-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x819_img-3945-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1279_img-3945-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x399_img-3945-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x639_img-3945-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x399_img-3945-jpg.jpg",
            "width": 5621
         },
         {
            "faces": [],
            "height": 3924,
            "name": "IMG_4060.jpg",
            "slug": "img-4060-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-4060-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4060-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-4060-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-4060-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-4060-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-4060-jpg.jpg",
            "width": 2803
         },
         {
            "faces": [],
            "height": 3624,
            "name": "IMG_3954.jpg",
            "slug": "img-3954-jpg",
            "src": "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x639_img-3954-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x499_img-3954-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg",
            "width": 5799
         },
         {
            "faces": [],
            "height": 6142,
            "name": "IMG_4100.jpg",
            "slug": "img-4100-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4100-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4100-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4100-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4100-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4100-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4100-jpg.jpg",
            "width": 4387
         },
         {
            "faces": [],
            "height": 5013,
            "name": "IMG_4148.jpg",
            "slug": "img-4148-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4148-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4148-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg",
            "width": 4010
         },
         {
            "faces": [],
            "height": 5220,
            "name": "IMG_4162.jpg",
            "slug": "img-4162-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4162-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4162-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg",
            "width": 4176
         }
      ],
      "slug": "octavio-m",
      "src": "/static/_gallery/albums/loop-marquesa/octavio-m_original_img-3163-jpg.jpg"
   },
   "omar-ibrahin": {
      "name": "Omar Ibrahin",
      "photos": [
         {
            "faces": [],
            "height": 4467,
            "name": "IMG_3077.jpg",
            "slug": "img-3077-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3077-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3077-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3077-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3077-jpg.jpg",
            "width": 6700
         },
         {
            "faces": [],
            "height": 5088,
            "name": "IMG_3093.jpg",
            "slug": "img-3093-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3093-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3093-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3093-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x624_img-3093-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3093-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x624_img-3093-jpg.jpg",
            "width": 4071
         },
         {
            "faces": [],
            "height": 4913,
            "name": "IMG_3094.jpg",
            "slug": "img-3094-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3094-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3094-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3094-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3094-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3094-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3094-jpg.jpg",
            "width": 3930
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 4367,
            "name": "IMG_3095.jpg",
            "slug": "img-3095-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3095-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3095-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3095-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x624_img-3095-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3095-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x624_img-3095-jpg.jpg",
            "width": 3494
         },
         {
            "faces": [],
            "height": 5982,
            "name": "IMG_3124.jpg",
            "slug": "img-3124-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3124-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3124-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3124-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3124-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3124-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3124-jpg.jpg",
            "width": 4273
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3100.jpg",
            "slug": "img-3100-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3100-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3100-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3100-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3100-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3100-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3100-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 4281,
            "name": "IMG_3304.jpg",
            "slug": "img-3304-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3304-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3304-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg",
            "width": 5352
         },
         {
            "faces": [],
            "height": 3251,
            "name": "IMG_3232.jpg",
            "slug": "img-3232-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x575_img-3232-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x449_img-3232-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4202,
            "name": "IMG_3306.jpg",
            "slug": "img-3306-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg",
            "width": 5253
         },
         {
            "faces": [],
            "height": 4060,
            "name": "IMG_3279.jpg",
            "slug": "img-3279-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x639_img-3279-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x499_img-3279-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg",
            "width": 6497
         },
         {
            "faces": [],
            "height": 4943,
            "name": "IMG_3311.jpg",
            "slug": "img-3311-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1599x2262_img-3311-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1448_img-3311-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1599x2262_img-3311-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x707_img-3311-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/799x1131_img-3311-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x707_img-3311-jpg.jpg",
            "width": 3495
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 4180,
            "name": "IMG_3300.jpg",
            "slug": "img-3300-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3300-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3300-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg",
            "width": 6270
         },
         {
            "faces": [],
            "height": 5909,
            "name": "IMG_3398.jpg",
            "slug": "img-3398-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2262_img-3398-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1448_img-3398-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2262_img-3398-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x707_img-3398-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1131_img-3398-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x707_img-3398-jpg.jpg",
            "width": 4178
         },
         {
            "faces": [],
            "height": 6116,
            "name": "IMG_3408.jpg",
            "slug": "img-3408-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2263_img-3408-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1448_img-3408-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2263_img-3408-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x707_img-3408-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1131_img-3408-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x707_img-3408-jpg.jpg",
            "width": 4324
         },
         {
            "faces": [],
            "height": 4349,
            "name": "IMG_3603.jpg",
            "slug": "img-3603-jpg",
            "src": "/static/_gallery/albums/otomi/1600x999_img-3603-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x639_img-3603-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x999_img-3603-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x312_img-3603-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x499_img-3603-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x312_img-3603-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 4309,
            "name": "IMG_3689.jpg",
            "slug": "img-3689-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2399_img-3689-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1535_img-3689-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2399_img-3689-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x749_img-3689-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1199_img-3689-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x749_img-3689-jpg.jpg",
            "width": 2873
         },
         {
            "faces": [],
            "height": 6209,
            "name": "IMG_3693.jpg",
            "slug": "img-3693-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3693-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3693-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3693-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3693-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3693-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3693-jpg.jpg",
            "width": 4435
         },
         {
            "faces": [],
            "height": 5305,
            "name": "IMG_4005.jpg",
            "slug": "img-4005-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg",
            "width": 3789
         },
         {
            "faces": [],
            "height": 5672,
            "name": "IMG_4015.jpg",
            "slug": "img-4015-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg",
            "width": 4537
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_4068.jpg",
            "slug": "img-4068-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4068-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4068-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5583,
            "name": "IMG_4017.jpg",
            "slug": "img-4017-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-4017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-4017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-4017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-4017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-4017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-4017-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5584,
            "name": "IMG_4044.jpg",
            "slug": "img-4044-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4044-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4044-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg",
            "width": 4467
         },
         {
            "faces": [],
            "height": 5748,
            "name": "IMG_3997.jpg",
            "slug": "img-3997-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3997-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3997-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg",
            "width": 4105
         },
         {
            "faces": [],
            "height": 5583,
            "name": "IMG_4070.jpg",
            "slug": "img-4070-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-4070-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-4070-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-4070-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-4070-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-4070-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-4070-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 6275,
            "name": "IMG_4112.jpg",
            "slug": "img-4112-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4112-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4112-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg",
            "width": 4183
         },
         {
            "faces": [],
            "height": 5013,
            "name": "IMG_4148.jpg",
            "slug": "img-4148-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4148-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4148-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4148-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4148-jpg.jpg",
            "width": 4010
         },
         {
            "faces": [],
            "height": 4784,
            "name": "IMG_4122.jpg",
            "slug": "img-4122-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2000_img-4122-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4122-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2000_img-4122-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4122-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1000_img-4122-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4122-jpg.jpg",
            "width": 3827
         },
         {
            "faces": [],
            "height": 5220,
            "name": "IMG_4162.jpg",
            "slug": "img-4162-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4162-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4162-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg",
            "width": 4176
         }
      ],
      "slug": "omar-ibrahin",
      "src": "/static/_gallery/albums/loop-marquesa/omar-ibrahin_original_img-3077-jpg.jpg"
   },
   "piero-vera": {
      "name": "Piero Vera",
      "photos": [
         {
            "faces": [],
            "height": 4283,
            "name": "IMG_3260.jpg",
            "slug": "img-3260-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1142_img-3260-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x731_img-3260-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1142_img-3260-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x357_img-3260-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x571_img-3260-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x357_img-3260-jpg.jpg",
            "width": 5996
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         }
      ],
      "slug": "piero-vera",
      "src": "/static/_gallery/albums/loop-marquesa/piero-vera_original_img-3260-jpg.jpg"
   },
   "raul-cuevas": {
      "name": "Raul Cuevas",
      "photos": [
         {
            "faces": [],
            "height": 5393,
            "name": "IMG_3116.jpg",
            "slug": "img-3116-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1599x2400_img-3116-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3116-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1599x2400_img-3116-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3116-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/799x1200_img-3116-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3116-jpg.jpg",
            "width": 3595
         },
         {
            "faces": [],
            "height": 5632,
            "name": "IMG_3126.jpg",
            "slug": "img-3126-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2240_img-3126-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3126-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2240_img-3126-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x700_img-3126-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1120_img-3126-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x700_img-3126-jpg.jpg",
            "width": 4022
         },
         {
            "faces": [],
            "height": 5601,
            "name": "IMG_3112.jpg",
            "slug": "img-3112-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2240_img-3112-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3112-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2240_img-3112-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x700_img-3112-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1120_img-3112-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x700_img-3112-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 6040,
            "name": "IMG_3128.jpg",
            "slug": "img-3128-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2240_img-3128-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3128-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2240_img-3128-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x700_img-3128-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1120_img-3128-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x700_img-3128-jpg.jpg",
            "width": 4314
         },
         {
            "faces": [],
            "height": 5777,
            "name": "IMG_3113.jpg",
            "slug": "img-3113-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2400_img-3113-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3113-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2400_img-3113-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3113-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1200_img-3113-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3113-jpg.jpg",
            "width": 3851
         },
         {
            "faces": [],
            "height": 3197,
            "name": "IMG_3168.jpg",
            "slug": "img-3168-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x640_img-3168-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1000_img-3168-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x500_img-3168-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3168-jpg.jpg",
            "width": 5115
         },
         {
            "faces": [],
            "height": 5982,
            "name": "IMG_3124.jpg",
            "slug": "img-3124-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3124-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3124-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3124-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3124-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3124-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3124-jpg.jpg",
            "width": 4273
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3762,
            "name": "IMG_3276.jpg",
            "slug": "img-3276-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x553_img-3276-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x432_img-3276-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg",
            "width": 6959
         },
         {
            "faces": [],
            "height": 4281,
            "name": "IMG_3304.jpg",
            "slug": "img-3304-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3304-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3304-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg",
            "width": 5352
         },
         {
            "faces": [],
            "height": 3251,
            "name": "IMG_3232.jpg",
            "slug": "img-3232-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x575_img-3232-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x899_img-3232-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x449_img-3232-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3232-jpg.jpg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4202,
            "name": "IMG_3306.jpg",
            "slug": "img-3306-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg",
            "width": 5253
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 3880,
            "name": "IMG_3274.jpg",
            "slug": "img-3274-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3274-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3274-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg",
            "width": 5820
         },
         {
            "faces": [],
            "height": 5422,
            "name": "IMG_3484.jpg",
            "slug": "img-3484-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2240_img-3484-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3484-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2240_img-3484-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x700_img-3484-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1120_img-3484-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x700_img-3484-jpg.jpg",
            "width": 3872
         },
         {
            "faces": [],
            "height": 4322,
            "name": "IMG_3475.jpg",
            "slug": "img-3475-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3475-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3475-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3475-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x624_img-3475-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3475-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x624_img-3475-jpg.jpg",
            "width": 3458
         },
         {
            "faces": [],
            "height": 5711,
            "name": "IMG_3895.jpg",
            "slug": "img-3895-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3895-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3895-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3895-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3895-jpg.jpg",
            "width": 4079
         },
         {
            "faces": [],
            "height": 5135,
            "name": "IMG_3668.jpg",
            "slug": "img-3668-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-3668-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-3668-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-3668-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3668-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3668-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3668-jpg.jpg",
            "width": 4268
         },
         {
            "faces": [],
            "height": 4911,
            "name": "IMG_3914.jpg",
            "slug": "img-3914-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1231_img-3914-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1924_img-3914-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-3914-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-3914-jpg.jpg",
            "width": 4082
         },
         {
            "faces": [],
            "height": 4913,
            "name": "IMG_3924.jpg",
            "slug": "img-3924-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2240_img-3924-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3924-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2240_img-3924-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3924-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1120_img-3924-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3924-jpg.jpg",
            "width": 3509
         },
         {
            "faces": [],
            "height": 5305,
            "name": "IMG_4005.jpg",
            "slug": "img-4005-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg",
            "width": 3789
         },
         {
            "faces": [],
            "height": 5987,
            "name": "IMG_4038.jpg",
            "slug": "img-4038-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4038-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4038-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4038-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4038-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4038-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4038-jpg.jpg",
            "width": 3991
         },
         {
            "faces": [],
            "height": 5672,
            "name": "IMG_4015.jpg",
            "slug": "img-4015-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4015-jpg.jpg",
            "width": 4537
         },
         {
            "faces": [],
            "height": 3624,
            "name": "IMG_3954.jpg",
            "slug": "img-3954-jpg",
            "src": "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x639_img-3954-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x999_img-3954-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x499_img-3954-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x312_img-3954-jpg.jpg",
            "width": 5799
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_4068.jpg",
            "slug": "img-4068-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4068-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4068-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4068-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4068-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5583,
            "name": "IMG_4017.jpg",
            "slug": "img-4017-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-4017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-4017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-4017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x601_img-4017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-4017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x601_img-4017-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5584,
            "name": "IMG_4044.jpg",
            "slug": "img-4044-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4044-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4044-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4044-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4044-jpg.jpg",
            "width": 4467
         },
         {
            "faces": [],
            "height": 5270,
            "name": "IMG_4019.jpg",
            "slug": "img-4019-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2400_img-4019-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4019-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2400_img-4019-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4019-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1200_img-4019-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4019-jpg.jpg",
            "width": 3513
         },
         {
            "faces": [],
            "height": 4888,
            "name": "IMG_4072.jpg",
            "slug": "img-4072-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1925_img-4072-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1232_img-4072-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1925_img-4072-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x601_img-4072-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x962_img-4072-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x601_img-4072-jpg.jpg",
            "width": 4062
         },
         {
            "faces": [],
            "height": 6275,
            "name": "IMG_4112.jpg",
            "slug": "img-4112-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4112-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4112-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg",
            "width": 4183
         },
         {
            "faces": [],
            "height": 4640,
            "name": "IMG_4047.jpg",
            "slug": "img-4047-jpg",
            "src": "/static/_gallery/albums/otomi/1600x1066_img-4047-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x682_img-4047-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x1066_img-4047-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x333_img-4047-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x533_img-4047-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x333_img-4047-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 5220,
            "name": "IMG_4162.jpg",
            "slug": "img-4162-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4162-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4162-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4162-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4162-jpg.jpg",
            "width": 4176
         }
      ],
      "slug": "raul-cuevas",
      "src": "/static/_gallery/albums/loop-marquesa/raul-cuevas_original_img-3116-jpg.jpg"
   },
   "regina-enriquez": {
      "name": "Regina Enriquez",
      "photos": [
         {
            "faces": [],
            "height": 5997,
            "name": "IMG_3142.jpg",
            "slug": "img-3142-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1599x2239_img-3142-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3142-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1599x2239_img-3142-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3142-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/799x1119_img-3142-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3142-jpg.jpg",
            "width": 4284
         },
         {
            "faces": [],
            "height": 3295,
            "name": "IMG_3162.jpg",
            "slug": "img-3162-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1280_img-3162-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3162-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1280_img-3162-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x400_img-3162-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x640_img-3162-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x400_img-3162-jpg.jpg",
            "width": 4118
         },
         {
            "faces": [],
            "height": 5914,
            "name": "IMG_3119.jpg",
            "slug": "img-3119-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2400_img-3119-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3119-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2400_img-3119-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3119-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1200_img-3119-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3119-jpg.jpg",
            "width": 3942
         },
         {
            "faces": [],
            "height": 5589,
            "name": "IMG_3115.jpg",
            "slug": "img-3115-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2400_img-3115-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3115-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2400_img-3115-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3115-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1200_img-3115-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3115-jpg.jpg",
            "width": 3726
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3762,
            "name": "IMG_3276.jpg",
            "slug": "img-3276-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x553_img-3276-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x432_img-3276-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg",
            "width": 6959
         },
         {
            "faces": [],
            "height": 4281,
            "name": "IMG_3304.jpg",
            "slug": "img-3304-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3304-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3304-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3304-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x399_img-3304-jpg.jpg",
            "width": 5352
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4202,
            "name": "IMG_3306.jpg",
            "slug": "img-3306-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3306-jpg.jpg",
            "width": 5253
         },
         {
            "faces": [],
            "height": 4060,
            "name": "IMG_3279.jpg",
            "slug": "img-3279-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x639_img-3279-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x499_img-3279-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg",
            "width": 6497
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 4943,
            "name": "IMG_3311.jpg",
            "slug": "img-3311-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1599x2262_img-3311-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1448_img-3311-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1599x2262_img-3311-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x707_img-3311-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/799x1131_img-3311-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x707_img-3311-jpg.jpg",
            "width": 3495
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 3880,
            "name": "IMG_3274.jpg",
            "slug": "img-3274-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3274-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3274-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg",
            "width": 5820
         },
         {
            "faces": [],
            "height": 5164,
            "name": "IMG_3314.jpg",
            "slug": "img-3314-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3314-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3314-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3314-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3314-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3314-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3314-jpg.jpg",
            "width": 3689
         },
         {
            "faces": [],
            "height": 4180,
            "name": "IMG_3300.jpg",
            "slug": "img-3300-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3300-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3300-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg",
            "width": 6270
         },
         {
            "faces": [],
            "height": 5241,
            "name": "IMG_3479.jpg",
            "slug": "img-3479-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3479-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3479-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3479-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x624_img-3479-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3479-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x624_img-3479-jpg.jpg",
            "width": 4193
         },
         {
            "faces": [],
            "height": 4322,
            "name": "IMG_3475.jpg",
            "slug": "img-3475-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3475-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3475-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3475-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x624_img-3475-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3475-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x624_img-3475-jpg.jpg",
            "width": 3458
         }
      ],
      "slug": "regina-enriquez",
      "src": "/static/_gallery/albums/loop-marquesa/regina-enriquez_original_img-3142-jpg.jpg"
   },
   "roberto-carroz": {
      "name": "Roberto Carroz",
      "photos": [
         {
            "faces": [],
            "height": 6545,
            "name": "IMG_3685.jpg",
            "slug": "img-3685-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-3685-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-3685-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-3685-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x750_img-3685-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-3685-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x750_img-3685-jpg.jpg",
            "width": 4363
         },
         {
            "faces": [],
            "height": 2988,
            "name": "IMG_3605.jpg",
            "slug": "img-3605-jpg",
            "src": "/static/_gallery/albums/otomi/1599x1329_img-3605-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x851_img-3605-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x1329_img-3605-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x415_img-3605-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x664_img-3605-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x415_img-3605-jpg.jpg",
            "width": 3595
         },
         {
            "faces": [],
            "height": 6441,
            "name": "IMG_3846.jpg",
            "slug": "img-3846-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-3846-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3846-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-3846-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-3846-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-3846-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-3846-jpg.jpg",
            "width": 4601
         },
         {
            "faces": [],
            "height": 5953,
            "name": "IMG_3850.jpg",
            "slug": "img-3850-jpg",
            "src": "/static/_gallery/albums/otomi/1599x2240_img-3850-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3850-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1599x2240_img-3850-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-3850-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/799x1120_img-3850-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-3850-jpg.jpg",
            "width": 4252
         },
         {
            "faces": [],
            "height": 5305,
            "name": "IMG_4005.jpg",
            "slug": "img-4005-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-4005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-4005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x700_img-4005-jpg.jpg",
            "width": 3789
         },
         {
            "faces": [],
            "height": 6495,
            "name": "IMG_4088.jpg",
            "slug": "img-4088-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2239_img-4088-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-4088-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2239_img-4088-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x699_img-4088-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1119_img-4088-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x699_img-4088-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5748,
            "name": "IMG_3997.jpg",
            "slug": "img-3997-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1433_img-3997-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2240_img-3997-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1120_img-3997-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/499x700_img-3997-jpg.jpg",
            "width": 4105
         },
         {
            "faces": [],
            "height": 6275,
            "name": "IMG_4112.jpg",
            "slug": "img-4112-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1536_img-4112-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2400_img-4112-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1200_img-4112-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x750_img-4112-jpg.jpg",
            "width": 4183
         },
         {
            "faces": [],
            "height": 5189,
            "name": "IMG_4127.jpg",
            "slug": "img-4127-jpg",
            "src": "/static/_gallery/albums/otomi/1600x2000_img-4127-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/otomi/1024x1280_img-4127-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/otomi/1600x2000_img-4127-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/otomi/500x625_img-4127-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/otomi/800x1000_img-4127-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/otomi/500x625_img-4127-jpg.jpg",
            "width": 4151
         }
      ],
      "slug": "roberto-carroz",
      "src": "/static/_gallery/albums/otomi/roberto-carroz_original_img-3685-jpg.jpg"
   },
   "shannon": {
      "name": "Shannon",
      "photos": [
         {
            "faces": [],
            "height": 4922,
            "name": "IMG_3103.jpg",
            "slug": "img-3103-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3103-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3103-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3103-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x624_img-3103-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3103-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x624_img-3103-jpg.jpg",
            "width": 3938
         },
         {
            "faces": [],
            "height": 3295,
            "name": "IMG_3162.jpg",
            "slug": "img-3162-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1280_img-3162-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3162-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1280_img-3162-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x400_img-3162-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x640_img-3162-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x400_img-3162-jpg.jpg",
            "width": 4118
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 5822,
            "name": "IMG_3139.jpg",
            "slug": "img-3139-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1599x2239_img-3139-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3139-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1599x2239_img-3139-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3139-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/799x1119_img-3139-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3139-jpg.jpg",
            "width": 4159
         },
         {
            "faces": [],
            "height": 3762,
            "name": "IMG_3276.jpg",
            "slug": "img-3276-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x553_img-3276-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x432_img-3276-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg",
            "width": 6959
         },
         {
            "faces": [],
            "height": 4146,
            "name": "IMG_3270.jpg",
            "slug": "img-3270-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3270-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3270-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3270-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3270-jpg.jpg",
            "width": 6220
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4060,
            "name": "IMG_3279.jpg",
            "slug": "img-3279-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x639_img-3279-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x499_img-3279-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg",
            "width": 6497
         },
         {
            "faces": [],
            "height": 4206,
            "name": "IMG_3272.jpg",
            "slug": "img-3272-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3272-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3272-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3272-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3272-jpg.jpg",
            "width": 6309
         },
         {
            "faces": [],
            "height": 5513,
            "name": "IMG_3325.jpg",
            "slug": "img-3325-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2239_img-3325-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3325-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2239_img-3325-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x699_img-3325-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1119_img-3325-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x699_img-3325-jpg.jpg",
            "width": 3938
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         },
         {
            "faces": [],
            "height": 3880,
            "name": "IMG_3274.jpg",
            "slug": "img-3274-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3274-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3274-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3274-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3274-jpg.jpg",
            "width": 5820
         },
         {
            "faces": [],
            "height": 4180,
            "name": "IMG_3300.jpg",
            "slug": "img-3300-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3300-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3300-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3300-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3300-jpg.jpg",
            "width": 6270
         },
         {
            "faces": [],
            "height": 6960,
            "name": "IMG_3433.jpg",
            "slug": "img-3433-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2400_img-3433-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1536_img-3433-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2400_img-3433-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x750_img-3433-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1200_img-3433-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x750_img-3433-jpg.jpg",
            "width": 4640
         }
      ],
      "slug": "shannon",
      "src": "/static/_gallery/albums/loop-marquesa/shannon_original_img-3103-jpg.jpg"
   },
   "xime-calzada": {
      "name": "Xime Calzada",
      "photos": [
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3163.jpg",
            "slug": "img-3163-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3163-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3163-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3163-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3163-jpg.jpg",
            "width": 5872
         },
         {
            "faces": [],
            "height": 4367,
            "name": "IMG_3095.jpg",
            "slug": "img-3095-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1999_img-3095-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1279_img-3095-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1999_img-3095-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/499x624_img-3095-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x999_img-3095-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/499x624_img-3095-jpg.jpg",
            "width": 3494
         },
         {
            "faces": [],
            "height": 5429,
            "name": "IMG_3133.jpg",
            "slug": "img-3133-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1599x2240_img-3133-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1433_img-3133-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1599x2240_img-3133-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x700_img-3133-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/799x1120_img-3133-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x700_img-3133-jpg.jpg",
            "width": 3877
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3193.jpg",
            "slug": "img-3193-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3193-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3762,
            "name": "IMG_3276.jpg",
            "slug": "img-3276-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x553_img-3276-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x864_img-3276-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x432_img-3276-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x270_img-3276-jpg.jpg",
            "width": 6959
         },
         {
            "faces": [],
            "height": 5800,
            "name": "IMG_3316.jpg",
            "slug": "img-3316-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x1280_img-3316-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x2000_img-3316-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x1000_img-3316-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x625_img-3316-jpg.jpg",
            "width": 4640
         },
         {
            "faces": [],
            "height": 3711,
            "name": "IMG_3237.jpg",
            "slug": "img-3237-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x819_img-3237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1279_img-3237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x639_img-3237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x399_img-3237-jpg.jpg",
            "width": 4639
         },
         {
            "faces": [],
            "height": 4060,
            "name": "IMG_3279.jpg",
            "slug": "img-3279-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x639_img-3279-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x999_img-3279-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x499_img-3279-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x312_img-3279-jpg.jpg",
            "width": 6497
         },
         {
            "faces": [],
            "height": 3193,
            "name": "IMG_3246.jpg",
            "slug": "img-3246-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x682_img-3246-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x1066_img-3246-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x533_img-3246-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x333_img-3246-jpg.jpg",
            "width": 4790
         },
         {
            "faces": [],
            "height": 3915,
            "name": "IMG_3280.jpg",
            "slug": "img-3280-jpg",
            "src": "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/loop-marquesa/1024x576_img-3280-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/loop-marquesa/1600x900_img-3280-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/loop-marquesa/800x450_img-3280-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/loop-marquesa/500x281_img-3280-jpg.jpg",
            "width": 6960
         }
      ],
      "slug": "xime-calzada",
      "src": "/static/_gallery/albums/loop-marquesa/xime-calzada_original_img-3163-jpg.jpg"
   }
};